import React, {Component} from "react"

class MenuView extends Component {
    render() {
        return (
            <div className="content container">
                <div id="carouselExampleControls" className="carousel slide full-width-carrousel" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{background: "url('/images/menu/header3.jpg') no-repeat center", backgroundSize: "100%"}}><span style={{display: "inline-block", height: "10rem"}}></span></div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a> */}
                </div>
                <h2>Menukaart</h2>
                <p>Onze ijskarren rijden doorheen Lommel & Pelt met de volgende smaken:</p>
                <ul>
                    <li>Vanille</li>
                    <li>Mokka</li>
                    <li>Chocolade</li>
                    <li>Aardbei</li>
                    <li>Banaan</li>
                    <li>Pistache</li>
                    <li>Stracciatella</li>
                    <li>Citroen</li>
                    <li>Suikervrije Vanille</li>
                </ul>
                <p>Ook hebben we een extra smaak van de dag bij!</p>
            </div>
        )
    }
}

export default MenuView