import React, {Component} from "react"
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import {store} from "../App";
import { setHideHeaderLinks } from "../Redux/OverlayActions";

const updateBarStyle = {
    backgroundColor: process.env.REACT_APP_THEME_COLOR,
    textAlign: "center",
    color: process.env.REACT_APP_THEME_TEXT_COLOR,
    padding: "0.25rem",
    display: "none",
    zIndex: "1030",
}

class Header extends Component {
    constructor() {
        super();
        this.state = {
            expanded : false
        };
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.closeExpanded = this.closeExpanded.bind(this);
        this.checkConsent = this.checkConsent.bind(this);
    }

    componentDidMount() {
        this.checkConsent();
    }

    checkConsent() {
        const consent = (window.localStorage.getItem('consent') || false);
        store.dispatch(setHideHeaderLinks(!consent));
    }

    toggleExpanded()
    {
        this.setState(prevState => ({expanded : !prevState.expanded}));
    }

    closeExpanded(){
        this.setState({expanded : false});
    }
    
    render() {
        if (this.props.hideHeader) {
            return null;
        }

        const overlay = (this.state.expanded ? <div onClick={this.closeExpanded} data-toggle="collapse" data-target="#navbarNavAltMarkup" className="dark-overlay"></div> : <div></div>);
        const hamburgerIcon = (this.state.expanded ? '/images/menu close.png' : '/images/menu hamburger.png');
        const menuItems = (!this.props.hideHeaderLinks ? 
            <div>
                <button style={{padding: "0", border: "none"}} onClick={this.toggleExpanded} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <img style={{width: "40px"}} src={window.location.origin + hamburgerIcon} alt="hamburger icon" />
                </button>
                <div onClick={this.closeExpanded} className="collapse navbar-collapse" id="navbarNavAltMarkup" data-toggle="collapse" data-target="#navbarNavAltMarkup">
                    <Link className="nav-item nav-link bordered" to="/">Tracker</Link>
                    <Link className="nav-item nav-link" to="/menu">Menukaart</Link>
                    <Link className="nav-item nav-link" to="/reserveren">IJskar reserveren</Link>
                    {/* <Link className="nav-item nav-link" to="/about">Over onze ijskarren</Link> */}
                    <Link className="nav-item nav-link" to="/status">Ijskarrenstatus</Link>
                    <Link className="nav-item nav-link" to="/aboutapp">Over deze app</Link>
                    {/* <a  href="https://www.foubert.eu/jobs" className="nav-item nav-link bordered no-big">jobs @ foubert</a> */}
                    {/* <a  href="https://www.foubert.eu/" className="nav-item nav-link no-big">foubert.eu</a> */}
                    {/* <a  href="https://shop.foubert.eu/" className="nav-item nav-link no-big"><span className="webshop-black">shop.</span><span className="webshop-black">foubert.eu</span></a> */}
                </div>
            </div>
        : null);
        return (
            <div className="fixed-top">
                <div id="updateBar" style={updateBarStyle}>
                    <a id="updateBarReload">Er is een nieuwe versie van de app beschikbaar. Klik hier om te updaten.</a>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light" style={{zIndex: "1030"}}>
                    <div className="container">
                        <Link className="navbar-brand" to="/"><img id="logo" alt="logo de krijmboer" src={window.location.origin + '/images/logo.png'}/></Link>
                        {menuItems}
                    </div>
                </nav>
                {overlay}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        hideHeader: state.overlay.hideHeader || false,
        hideHeaderLinks: state.overlay.hideHeaderLinks || false,
    }
}

export default connect(mapStateToProps)(Header);